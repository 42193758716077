import * as React from 'react';
import { 
  Dimensions, 
  Image, 
  TouchableOpacity, 
  View, 
  FlatList, 
  StyleSheet, 
  Text,
  Linking,
  Platform,
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Constants from 'expo-constants';


class HomeScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: []
    };
  }

  resize = () => this.forceUpdate()

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.makeRemoteRequest();
    console.log('OXOXOXOX ');
  }

componentWillUnmount() {
  window.removeEventListener('resize', this.resize)
}

  makeRemoteRequest = () => {

    // if(Platform.OS == 'web'){
    const urlParams = Platform.OS == 'web' ? new URLSearchParams(window.location.search) : null;
    const card = urlParams ? urlParams.get('card') : null;
      // if(card){
    const url = (Platform.OS == 'web' && card) ? 'https://0w8uw4c6a7.execute-api.us-east-1.amazonaws.com/prod/top/?card=' + card : `https://0w8uw4c6a7.execute-api.us-east-1.amazonaws.com/prod/cards/`; // `http://localhost:8000/list_data.json`; //`http://127.0.0.1:8000/cards/`; //https://berniereport.com/data/list_data.json`;
    // };
  

    fetch(url, { mode: 'cors'})  // fetch(url, { mode: 'no-cors'})
      .then(res => res.json())
      .then(res => {
        console.log('OXOXOXOX ', res); //+ JSON.stringify(res));
        this.setState({
          data: res
        });
      })
  };

  render() {
    return (

      // <SafeAreaView style={styles.container}>
<View style={{backgroundColor: "#ccc", width: "100%", height: "100%", 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                alignItems: 'center',
      
      }}>
      <FlatList
      style={{
        width: Math.round(Math.min(Dimensions.get('window').width, Dimensions.get('window').height))
      }}
        //  data={DATA}
        data={this.state.data}
        renderItem={({ item }) => 
        // <TouchableOpacity
        // onPress={() => this.props.navigation.navigate('report', {
        //   url: item.url,
        // })}
        // >
        <TouchableOpacity
        onPress={() => {
          // Linking.openURL(item.url)
          if(Platform.OS == 'web'){
            window.open(item.url, '_top');
         } else {
          Linking.openURL(item.url)
         }
        }}
      >
          <View style={{ 
            width: "100%",
            flexDirection: 'column', 
            justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          paddingHorizontal: 0,      
          paddingTop: Math.round(
            Math.min(Dimensions.get('window').width, Dimensions.get('window').height) * 0.03 
          ),      
          paddingBottom: Math.round(
            Math.min(Dimensions.get('window').width, Dimensions.get('window').height) * 0.02 
          ),      
          marginVertical: "2%",
            // width: Math.round(
            //   ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.88) *  item.im_width 
            // ), 
            // height: Math.round(
            //   ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.88) * item.im_height 
            // ), 
          }}>

{!!item.why && <View><Text style={[
        styles.title,
        { maxWidth: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 16) * 0.94) *  16 
        ),
        }  
      ]}>{item.headline}</Text>
      <Text style={[
        styles.why,
        { maxWidth: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 16) * 0.94) *  16 
        ),
        }  
      ]}>{item.why}</Text></View>}



          <Image
          style={{
            width: Math.round(
              ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 16) * 0.94) *  16 
            ), 
            height: Math.round(
              ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 16) * 0.94) * 9 
            ), 
          }}
          source={{uri: item.image}}
        />
      {/* <View style={
        { width: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / item.im_width) * 0.94) *  item.im_width 
        ),
        }
        }> */}
{!item.why && <Text style={[
        styles.title,
        { maxWidth: Math.round(
          ((Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / 16) * 0.94) *  16 
        ),
        }  
      ]}>{item.headline}</Text>}
      {/* </View> */}
    </View></TouchableOpacity>
        }
        keyExtractor={item => item.id}
      />
      </View>

    );
  }
}

const Stack = createStackNavigator();

function RootStack() {
  return (
    <Stack.Navigator
      initialRouteName="EQUALTRUTH.COM"
      screenOptions={{ gestureEnabled: false }}
    >
      <Stack.Screen
        name="EQUALTRUTH.COM"
        component={HomeScreen}
      />
    </Stack.Navigator>
  );
}

export default function App() {
  return <NavigationContainer>
    <RootStack />
  </NavigationContainer>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: Constants.statusBarHeight,
  },
  item: {
    backgroundColor: '#fff',
    padding: 0,
    marginVertical: "6%",
    marginHorizontal: "6%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 26,
    marginVertical: "2%",
    marginHorizontal: "0%",
  },
  why: {
    fontSize: 20,
    marginVertical: "2%",
    marginHorizontal: "0%",
  },
  marginz: {
    marginVertical: "2%",
    marginHorizontal: "0%",
  },
});
